import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import Tile from "./Tile";

function HalloweenSlider() {
  const [data, setData] = useState(null);
  useEffect(() => {
    fetch(`./assets/data/halloween-items.json?r=${Date.now()}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        setData(myJson);
      });
  }, []);
  let heroImage = "./assets/images/love-bg.webp";
  return (
    <section
      id="titles"
      className="padVer"
      style={{
        backgroundImage: `url(${heroImage})`,
        backgroundSize: "cover",
        boxShadow: "inset 0 0 50px 50px #0c0c1c",
      }}
    >
      <div className="container-fluid">
        <div className="text-center mb-5">
          {/* <img
            className="mb-2"
            src="/assets/images/afawesomeholiday-logo-r-e.png"
            alt="A Fawesome Holiday"
          /> */}
          <h2>Free Romance & Valentine’s Vibes</h2>
        </div>
        <div className="titles-slide">{!data ? "" : getSliderItems(data)}</div>
      </div>
    </section>
  );
}

function getSliderItems(data) {
  let settings = {
    dots: false,
    lazyLoad: "ondemand",
    infinite: true,
    speed: 500,
    slidesToShow: 7,
    focusOnSelect: true,
    autoplay: true,
    slidesToScroll: 1,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Slider {...settings}>
      {data.map((item, key) => {
        return item.nodeId ? <Tile item={item} key={key} /> : "";
      })}
    </Slider>
  );
}
export default HalloweenSlider;
