import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import Tile from "./Tile";

function TilesSlider({ title, json }) {
  const [data, setData] = useState(null);
  useEffect(() => {
    /*let pageTitle =
      "Watch Free Movies and TV Shows Online | Free Streaming Video | Fawesome";
    let pageDescription =
      "Watch free movies & TV shows across 25+ genres on Fawesome | No Subscription | No Registration | Instant Streaming | Full movies in HD";
    let pageKeywords =
      "Free movies online, Watch free movies, Watch free movies online, Free movies, Free movies online sites, Action movies, Mystery movies free, horror movies free, Free movies on youtube, Free shows on youtube";

    const metaOgTitle = document.querySelector('meta[property="og:title"]');
    const metaTwitterTitle = document.querySelector(
      'meta[name="twitter:title"]'
    );*/

    //document.title = pageTitle;
    /*if (metaOgTitle) {
      metaOgTitle.setAttribute("content", pageTitle);
    }
    if (metaTwitterTitle) {
      metaTwitterTitle.setAttribute("content", pageTitle);
    }*/

    /*const updateMetaTag = (name, content) => {
      const metaTag = document.querySelector(
        `meta[name="${name}"], meta[property="${name}"]`
      );
      if (metaTag) {
        metaTag.setAttribute("content", content);
      }
    };*/

    //updateMetaTag("description", pageDescription);
    //updateMetaTag("og:description", pageDescription);
    //updateMetaTag("twitter:description", pageDescription);
    //updateMetaTag("keywords", pageKeywords);

    fetch(`${json}?r=${Date.now()}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        setData(myJson);
      });
  }, []);
  return (
    <section id="titles" className="padVer">
      <div className="container-fluid">
        <div className="text-center mb-5">
          <h2>{title}</h2>
        </div>
        <div className="titles-slide">{!data ? "" : getSliderItems(data)}</div>
      </div>
    </section>
  );
}

function getSliderItems(data) {
  let settings = {
    dots: false,
    lazyLoad: "ondemand",
    infinite: true,
    speed: 500,
    slidesToShow: 7,
    focusOnSelect: true,
    autoplay: true,
    slidesToScroll: 1,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Slider {...settings}>
      {data.map((item, key) => {
        return item.nodeId ? <Tile item={item} key={key} /> : "";
      })}
    </Slider>
  );
}
export default TilesSlider;
