import React, { useState, useEffect } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import { useParams } from "react-router";
import { Nav, Navbar, Container, NavDropdown } from "react-bootstrap";
let debounceTimer;
function BlogDetailsHero(data) {
  let listData = data.data;
  // const { id } = useParams();
  // const [listData, setListData] = useState({});
  // useEffect(() => {
  //   fetch("./assets/data/blogs.json", {
  //     headers: {
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //     },
  //   })
  //     .then(function (response) {
  //       return response.json();
  //     })
  //     .then(function (myJson) {
  //       const getDetails = myJson.filter((val) => val.id == id);
  //       setListData(getDetails[0]);
  //     });
  // }, []);
  let logoFawesome = "./assets/images/logo-fawesome.svg";
  let searchIcon = "./assets/images/search.svg";
  const [inputValue, setInputValue] = useState("");
  let blogImage = listData?.image;
  if (listData?.image_banner) {
    blogImage = listData?.image_banner;
  }

  useEffect(() => {
    const navLinks = document.querySelectorAll(".bg-body-tertiary .nav-link");
    const searchInput = document.querySelectorAll(
      ".bg-body-tertiary #search-input"
    );
    navLinks.forEach((link) => {
      link.classList.add("gtm-lp", "gtm-nav-link");
    });
    searchInput.forEach((link) => {
      link.classList.add("gtm-lp", "gtm-search-text");
    });
  }, []);

  const menuInfo = [
    { name: "Home", link: `${document.location.origin}/home/` },
    {
      name: "Collections",
      link: `${document.location.origin}/categories/collections`,
    },
    { name: "Shows", link: `${document.location.origin}/categories/shows` },
    {
      name: "Best Movies",
      link: `${document.location.origin}/categories/best-movies`,
    },
    {
      name: "En Español",
      link: `${document.location.origin}/categories/en-español`,
    },
    { name: "Search", link: `${document.location.origin}/home/` },
  ];
  return (
    <>
      <Navbar className="bg-body-tertiary navbar-dark" expand="xl">
        <div className="container-fluid">
          <Navbar.Brand href="./">
            {/* <a
              className="navbar-brand"
              rel="noopener noreferrer"
              onClick={handleClick}
            > */}
            <img
              src={logoFawesome}
              className="img-fluid fw-logo"
              alt="Fawesome"
              // style="opacity: 1;"
            />
            {/* </a> */}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            {/* <Nav.Link href="#home">Home</Nav.Link>
            <Nav.Link href="#link">Collections</Nav.Link>
            <Nav.Link href="#link">Shows</Nav.Link>
            <Nav.Link href="#link">Best Movies</Nav.Link>
            <Nav.Link href="#link">Recommended</Nav.Link> */}
            {getNamedMenu()}
            {/* <Nav.Link>
              <div className="row ">
                <div className="input-group">
                  <img
                    src={searchIcon}
                    className="img-fluid fw-logo"
                    alt="Fawesome"
                    // style="opacity: 1;"
                  />
                  <input
                    className="form-control"
                    type="search"
                    value=""
                    id="search-input"
                    placeholder="Search"
                  />
                </div>
              </div>
            </Nav.Link> */}

            {/* <Nav.Link href="#link">About</Nav.Link> */}
          </Navbar.Collapse>
        </div>
      </Navbar>
      <div className="row col-md-12 col-sm-12">
        <div className="col-md-6 col-sm-12 de-mob-image">
          <img
            src={blogImage}
            className="heropic-de"
            alt={listData?.alt_text}
          />
        </div>
        <div className="col-md-6 col-sm-12">
          <div className="fCorLeftDe"></div>

          <div className="herotitleContDe">
            <p className="cta-intro-de">{listData?.date}</p>
            <div className="d-flex justify-content-start flex-wrap">
              <p>
                <img
                  style={{ width: "1em", marginBottom: "1em" }}
                  src="./assets/images/ico-timer.svg"
                ></img>
              </p>
              &nbsp;
              <div
                style={{ marginRight: "2em" }}
                className="bl-reading-time"
                dangerouslySetInnerHTML={{ __html: listData.reading_time }}
              />
              {/* <p>
                <a href={listData.author.url} target="_blank">
                  <img
                    style={{ width: "1em", marginBottom: "1em" }}
                    src="./assets/images/ico-linkedin.svg"
                  ></img>
                </a>
              </p>
              &nbsp; */}
              {/* <div className="bl-author" /> */}
              {/* <p className="blog-author">
                by:&nbsp;
                <a
                  href={listData.author.url}
                  target="_blank"
                  className="author-link"
                >
                  {listData.author.name}
                </a>
              </p> */}
            </div>

            <h1 className="herotitle-de">{listData?.title}</h1>
          </div>
        </div>

        <div className="col-md-6 col-sm-12 de-image">
          <img
            src={blogImage}
            className="heropic-de"
            alt={listData?.alt_text}
          />
        </div>
      </div>
    </>
  );

  function handleClick() {
    let urlFull = window.location.href;
    let url = urlFull;
    let qs = "";
    if (urlFull.indexOf("?") > -1) {
      url = urlFull.split("?")[0];
      qs = "?" + urlFull.split("?")[1];
    }
    var newUrl = url.replace(/\/#\/?$/, "");
    newUrl = window.location.protocol + "//" + window.location.hostname;
    var homePath = "/home/";
    if (newUrl.endsWith("/")) {
      homePath = "home/";
    }
    window.location.href = newUrl + homePath + qs;
  }

  function getNamedMenu() {
    return (
      <>
        {menuInfo.map((item, index) =>
          item.name == "Search" ? (
            <Nav.Link key={index} className="cursor-auto">
              <div className="row ">
                <div className="input-group">
                  <img
                    src={searchIcon}
                    className="img-fluid fw-logo"
                    alt="Fawesome"
                    // style="opacity: 1;"
                  />
                  <input
                    className="form-control"
                    // type="search"
                    id="search-input"
                    placeholder="Search"
                    value={inputValue}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                  />
                </div>
              </div>
            </Nav.Link>
          ) : (
            <Nav.Link href={item.link} key={index}>
              {item.name}
            </Nav.Link>
          )
        )}
      </>
    );
  }

  function isValidInputForSearch(str) {
    // Regex allows input starting with letters or numbers, followed by any characters except %/\?
    return /^[a-zA-Z0-9](?!.*[%\/\\?]).*$/.test(str);
  }

  function handleChange(event) {
    var currentValue = event.target.value;
    if (!isValidInputForSearch(currentValue)) {
      event.target.value = currentValue.slice(0, -1); // Remove the last character if invalid
    }
    var searchText = event.target.value;
    setInputValue(searchText);
    // Set debounce delay in milliseconds
    var debounceDelay = 2000;
    // Check if searchText has at least 2 letters
    if (searchText.length >= 2) {
      // Clear previous debounce timer
      clearTimeout(debounceTimer);
      // Set new debounce timer
      debounceTimer = setTimeout(function () {
        window.location.href = `${document.location.origin}/search/${searchText}`;
      }, debounceDelay);
    }
  }

  function handleKeyDown(event) {
    var searchText = event.target.value;
    clearTimeout(debounceTimer);
    if (event.key === "Enter") {
      if (searchText.length >= 2) {
        window.location.href = `${document.location.origin}/search/${searchText}`;
      }
    }
  }
}
export default BlogDetailsHero;
