import { useInView } from "react-intersection-observer";
import TilesSlider from "./TilesSlider";

function TilesSliderInView({ title, json }) {
  const { ref, inView } = useInView({
    triggerOnce: true,
    fallbackInView: true,
  });

  return (
    <div ref={ref}>
      {inView ? <TilesSlider title={title} json={json} /> : null}
    </div>
  );
}
export default TilesSliderInView;
