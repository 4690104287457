import { useInView } from "react-intersection-observer";

import React, { useState } from "react";
import { Accordion, Card, useAccordionButton } from "react-bootstrap";
function FAQ({ data }) {
  const { ref, inView } = useInView({
    triggerOnce: true,
    fallbackInView: true,
  });
  const [activeKey, setActiveKey] = useState();
  function CustomToggle({ children, eventKey, handleClick }) {
    const decoratedOnClick = useAccordionButton(eventKey, () => {
      handleClick();
    });

    return (
      <div className="faq-card-header" type="button" onClick={decoratedOnClick}>
        {children}
      </div>
    );
  }
  const data1 = [
    {
      name: "What is Fawesome?",
      answer:
        "Fawesome is a free movies website offering a vast selection of movies and TV shows. You can watch free movies online across various genres, from action to comedy, all without any subscription fees.",
    },
    {
      name: "Is Fawesome legal?",
      answer:
        "Yes, it’s 100% legal. Our content includes ads while making sure that you get to watch the best content out there.",
    },
    {
      name: "How can I watch free movies on Fawesome?",
      answer:
        "To watch free movies on Fawesome, simply visit our website or install the free movies app. Browse through our extensive collection and start streaming your favorite titles instantly.",
    },
    {
      name: "Do I need to create an account to watch movies for free?",
      answer:
        "No, you don’t need to create an account to watch free movies on Fawesome. Just visit our free movie website and start streaming your favorite movies and TV shows instantly.",
    },
    {
      name: "What kind of movies can I watch on Fawesome?",
      answer:
        "Fawesome offers a diverse selection of movies, including action, drama, comedy, horror, and more. Our collection includes both classic and contemporary titles, all available as free online movies.",
    },
    {
      name: "Can I watch TV shows for free on Fawesome?",
      answer:
        "Yes, Fawesome not only offers free movies to watch but also a great selection of TV shows. You can watch TV shows online free with high-quality streaming on our platform.",
    },
    {
      name: "How often is new content added to Fawesome?",
      answer:
        "We regularly update our library with new free movies online and TV shows. Keep checking back for the latest releases and new additions to our free movie streaming sites list.",
    },
    {
      name: "Can I watch free movies on my mobile device?",
      answer:
        "Yes, you can watch movies free online on any device, including smartphones and tablets. Install the Fawesome app to enjoy seamless streaming of free movies and TV shows on the go.",
    },
    {
      name: "What are the best free movies on Fawesome right now?",
      answer:
        "Check out our “Fawesome This Week” section to find the most popular free movies to watch. We update this regularly, so you can stay up-to-date with what’s hot on our free movie websites.",
    },
  ];
  return (
    <div ref={ref}>
      {inView ? (
        <section id="noSubInStr" className="padVer">
          <div className="container">
            <div className="row">
              <h2>Frequently Asked Questions</h2>
            </div>
            <div className="row mt-5">
              <Accordion className="faq-accordion">
                {data.map((item, index) => (
                  <Card key={index} className="faq-card">
                    <CustomToggle
                      as={Card.Header}
                      eventKey={index}
                      handleClick={() => {
                        if (activeKey === index) {
                          setActiveKey(null);
                        } else {
                          setActiveKey(index);
                        }
                      }}
                    >
                      {item.name}
                      <span className="faq-ex-cl">
                        {activeKey === index ? "-" : "+"}
                      </span>
                    </CustomToggle>
                    <Accordion.Collapse eventKey={index}>
                      <Card.Body className="faq-card-body">
                        {item.answer}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                ))}
              </Accordion>
            </div>
          </div>
        </section>
      ) : null}
    </div>
  );
}
export default FAQ;
