import ArtistForm from "../components/ArtistForm";
import FooterInView from "../components/FooterInView";
import Menu from "../components/Menu";
import FAQ from "../components/FAQ";
function PartnershipPage() {
  const faqData = [
    {
      name: "How long does it take for the movie to show up on the channel?",
      answer:
        "Once your movie is submitted to Fawesome, it typically takes two to four weeks for processing, quality checks, and platform integration before it goes live on our channel. Our dedicated team ensures your content meets all technical specifications for seamless streaming. ",
    },
    {
      name: "Where will the movie be available?",
      answer:
        "Your movie will be available for streaming across all major platforms where Fawesome is accessible, including Roku, Amazon Fire TV, Apple TV, Samsung Smart TVs, LG TVs, Vizio, and mobile apps for iOS and Android devices. Additionally, your content will reach viewers on our website providing broad exposure to an audience of movie lovers looking for free entertainment.",
    },
    {
      name: "Can I promote the movie on my social media handles?",
      answer:
        "Yes, we encourage creators to promote their movies on their social media platforms, websites, and marketing channels to maximize visibility. Sharing links, trailers, and engaging content on Facebook, Instagram, and YouTube can drive traffic to your movie on Fawesome. Please use #FreeOnFawesome, #FreeStreamingMovies, and #WatchFreeMovies to boost discoverability and join our growing community of independent filmmakers and creators. Make sure to refer to the brand guidelines for any assets that you create for the promotion of your movies.",
    },
  ];
  return (
    <>
      <Menu></Menu>
      <ArtistForm />
      <FAQ data={faqData}></FAQ>
      <div className="text-center show-all">
        <a
          href="https://drive.google.com/drive/folders/1HDUFQoQSOAK0wbiOQX6RGy9-rJ-GNm90"
          target="_blank"
          rel=""
          className="blog-link gtm-pp gtm-official-assets-brand-guidelines "
        >
          <p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              className="bi bi-download"
              viewBox="0 0 16 16"
              style={{ marginTop: "-6px" }}
            >
              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
              <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
            </svg>
            &nbsp; All official assets and brand guidelines
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-chevron-double-right"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708"
              />
              <path
                fillRule="evenodd"
                d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708"
              />
            </svg> */}
          </p>
        </a>
      </div>
      <div className="text-center show-all">
        <a
          href="https://drive.google.com/drive/folders/19h9znMhzqp57yH1uO-ohF6pfBQ1dCp7A"
          target="_blank"
          rel=""
          className="blog-link gtm-pp gtm-content-delivery-specifications "
        >
          <p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              className="bi bi-download"
              viewBox="0 0 16 16"
              style={{ marginTop: "-6px" }}
            >
              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
              <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
            </svg>
            &nbsp; Content Delivery Specifications
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-chevron-double-right"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708"
              />
              <path
                fillRule="evenodd"
                d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708"
              />
            </svg> */}
          </p>
        </a>
      </div>

      <div className="icon-container">
        <a
          href="https://www.youtube.com/@fawesometv"
          target="_blank"
          rel="noopener noreferrer"
          className="gtm-pp gtm-social-link"
        >
          <img
            src="/assets/images/yt.png"
            alt="YouTube"
            className="social-logo social-logo-lg"
          />
          {/* <span>YouTube</span> */}
        </a>
        <a
          href="https://www.instagram.com/fawesome_tv/"
          target="_blank"
          rel="noopener noreferrer"
          className="gtm-pp gtm-social-link"
        >
          <img
            src="/assets/images/insta.png"
            alt="Instagram"
            className="social-logo social-logo-lg"
          />
          {/* <span>Instagram</span> */}
        </a>
        <a
          href="https://www.facebook.com/profile.php?id=61562666679680"
          target="_blank"
          rel="noopener noreferrer"
          className="gtm-pp gtm-social-link"
        >
          <img
            src="/assets/images/ico-facebook.png"
            alt="Facebook"
            className="social-logo social-logo-lg"
          />
          {/* <span>Facebook</span> */}
        </a>
      </div>

      <FooterInView></FooterInView>
    </>
  );
}
export default PartnershipPage;
