import { useEffect, useRef } from "react";
import FooterInView from "../components/FooterInView";
import Menu from "../components/Menu";
function ArtistForm() {
  const iframeRef = useRef(null);
  // Adjust iframe height based on content size
  const adjustIframeHeight = () => {
    /*if (iframeRef.current) {
      try {
        const iframeDoc = iframeRef.current.contentWindow.document;
        const newHeight = iframeDoc.body.scrollHeight;
        iframeRef.current.style.height = `${newHeight}px`;
      } catch (error) {}
    }*/
  };

  useEffect(() => {
    // Resize on load and attach listener
    const iframe = iframeRef.current;
    if (iframe) {
      iframe.addEventListener("load", adjustIframeHeight);
    }

    return () => {
      if (iframe) {
        iframe.removeEventListener("load", adjustIframeHeight);
      }
    };
  }, []);

  return (
    <>
      <div className="container">
        <div className="row mb-5 mt-5">
          <h2>
            Are you an independent filmmaker or content creator looking to
            promote or distribute your work with Fawesome?
          </h2>
        </div>
        <div className=" mb-5">
          {/* <h4>
            Are you an independent filmmaker or content creator looking to
            promote or distribute your work with Fawesome?
          </h4> */}
          <p>
            We’re always excited to collaborate with talented creators who want
            to showcase their movies, shows, or other engaging content on our
            platform. Whether you're interested in content distribution or have
            a general inquiry, please fill out the form below. Our team will
            review your submission and get back to you as soon as possible.
          </p>
        </div>

        <div className="row bg-ligh1t">
          <iframe
            ref={iframeRef}
            src="https://docs.google.com/forms/d/e/1FAIpQLSfV2TXrVRPJaD9Mgk-wxhtqcOvo_UY9irzm2YSPzWNzgiRLLw/viewform?embedded=true"
            width="100%"
            height="600px"
          >
            Loading…
          </iframe>
        </div>
      </div>
    </>
  );
}
export default ArtistForm;
